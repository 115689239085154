import { message as msg } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { uploadInit, uploadUrl, uploadInitMulti } from "../../../Api/Upload";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { BACKEND_URL } from "../../../Action/types";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// let count = 0;

const Index = ({
  uploadInit,
  uploadInitMulti,
  uploadUrl,
  isError,
  isErrorType,
  isAuthenticated,
  errMessage,
  isUploading,
  selectedPrice,
  setselectedPrice,
  setshowModal,
}) => {
  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   arrows: false,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   pauseOnHover: true,
  // };
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");

  const sendMail = async () => {
    if (email !== "") {
      const config = {
        "Content-Type": "application/json",
      };
      const body = {
        name,
        email,
        subject,
        message,
        query_type: "landing page",
      };
      await axios({
        method: "POST",
        url: BACKEND_URL + "customer/query",
        data: body,
        headers: config,
      })
        .then((response) => {
          if (response.data.success) {
            msg.success("Query submitted. Will get back to you soon.");
            setname("");
            setemail("");
            setmessage("");
            setsubject("");
            setselectedPrice("");
          } else {
            msg.success("Something went wrong! Please try again.");
          }
        })
        .catch((error) => {
          msg.success("Something went wrong! Please try again.");
        });
    } else {
      msg.error("Please add email");
    }
  };

  // const [fileData, setfileData] = useState([]);
  // const [loader, setloader] = useState(true);
  const history = useHistory();
  // const [urlText, seturlText] = useState("");

  // const props = {
  //   width: "280px",
  //   multiple: false,
  //   onRemove: (file, fileList) => {
  //     const newFileList = fileData.filter((ele) => ele.uid !== file.uid);
  //     setfileData(newFileList);
  //   },
  //   beforeUpload: (file, fileList) => {
  //     setfileData([file]);
  //     return false;
  //   },
  //   onChange: (info) => {
  //     uploadInit(info.file, "");
  //     setloader(false);
  //     setTimeout(() => {
  //       setloader(true);
  //     }, 1);
  //   },
  //   fileData,
  // };
  // const props2 = {
  //   width: "280px",
  //   directory: true,
  //   multiple: false,
  //   onRemove: (file, fileList) => {
  //     const newFileList = fileData.filter((ele) => ele.uid !== file.uid);
  //     setfileData(newFileList);
  //   },
  //   beforeUpload: (file, fileList) => {
  //     // uploadInitMulti(fileList);
  //     setfileData(fileList);
  //     return false;
  //   },
  //   onChange: ({ file, fileList }) => {
  //     count++;
  //     if (count === fileList.length) {
  //       count = 0;
  //       uploadInitMulti(fileList, "");
  //       setloader(false);
  //       setTimeout(() => {
  //         setloader(true);
  //       }, 200);
  //     }
  //   },
  //   fileData,
  // };

  useEffect(() => {
    if (isError && isErrorType === "SUCCESS") {
      msg.success(errMessage);
      setTimeout(() => {
        history.push("/dashboard");
      }, 200);
    } else if (isError && isErrorType === "FAIL") {
      msg.error(errMessage);
      history.push("/dashboard");
    }
  }, [isError, isErrorType, errMessage, history]);

  useEffect(() => {
    if (selectedPrice !== "") {
      setsubject(selectedPrice);
    }
  }, [selectedPrice]);

  const sectionRef = useRef(null);

  const scrollToSection = () => {
    sectionRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="fontChange">
      <div className="position-relative z-index-1   mt-25 mt-md-15 mt-lg-0 mt-xl-22 mb-16">
        <div className="container">
          <div className="row position-relative align-items-center">
            <div
              className="col-xxl-7 col-xl-8 col-lg-9 pt-lg-16 pb-lg-16 pt-md-16"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="row">
                <div className="col-md-11 col-sm-11">
                  <h1
                    className="font-size-9 mb-8 mt-lg-14 mt-xl-16 mt-sm-0"
                    style={{ lineHeight: 1.4 }}
                  >
                    Revolutionize Your Legal Practice with{" "}
                    <span className="text-green">AI-Driven</span> Document
                    Management
                  </h1>
                  <p className="font-size-6 mb-8">
                    Streamline your case management and leverage AI-powered
                    insights to enhance your legal expertise.
                  </p>
                  <button
                    to="create-job"
                    type="button"
                    className="btn btn-primary font-size-3 font-weight-normal  text-uppercase mt-0 px-6"
                    onClick={(e) => {
                      if (isAuthenticated) {
                        history.push("/dashboard");
                      } else {
                        setshowModal(true);
                      }
                    }}
                  >
                    Try Now
                  </button>
                  <button
                    to="create-job"
                    type="button"
                    className="btn btn-outline-primary ml-3 font-size-3 font-weight-normal  text-uppercase mt-0 px-6"
                    onClick={(e) => {
                      scrollToSection();
                    }}
                  >
                    Request a Demo
                  </button>
                </div>
              </div>
              {/* <div className="pt-8 ">
                {isUploading ? (
                  <form className="search-form">
                    <div className="filter-search-form-2  rounded-sm shadow-6 pr-8 py-2 pl-6">
                      <div>
                        <img
                          src="./assets/image/loading.gif"
                          alt=""
                          style={{ width: "100px" }}
                        />
                        <span
                          style={{ fontWeight: "600", fontSize: "20px" }}
                          className="ml-8"
                        >
                          Processing please wait...
                        </span>
                      </div>
                    </div>
                  </form>
                ) : (
                  <form className="search-form">
                    <div className="filter-search-form-2  rounded-sm shadow-6 pr-8 py-8 pl-6">
                      <div className="filter-inputs">
                        <div className="form-group position-relative  ">
                          <input
                            className="form-control focus-reset pl-10 border border border border-primary "
                            type="text"
                            id="keyword"
                            placeholder="Enter URL"
                            value={urlText}
                            onChange={(e) => seturlText(e.target.value)}
                            style={{
                              height: "39px",
                              width: "Calc(100% - 20px)",
                            }}
                          />
                          <span className="h-100 w-px-30 pl-3 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                            <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                          </span>
                          <span
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "-12px",
                              transform: "translate(0px, -50%)",
                              fontWeight: "600",
                              background: "#fff",
                            }}
                          >
                            OR
                          </span>
                        </div>
                        <div className="form-group position-relative">
                          <div className="ml-10 pl-0">
                            <Popover
                              content={
                                <div
                                  style={{
                                    width: "240px",
                                    height: "120px",
                                    overflow: "hidden",
                                  }}
                                >
                                  {loader && (
                                    <>
                                      <Upload {...props}>
                                        <button
                                          to="create-job"
                                          style={{ width: "240px" }}
                                          className="btn btn-dark font-size-3 font-weight-normal  text-uppercase mt-2"
                                          onClick={() => {}}
                                        >
                                          Choose a file
                                        </button>
                                      </Upload>
                                    </>
                                  )}
                                  <div className="border-bottom position-relative mt-5 mb-5">
                                    <span
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                        fontWeight: "600",
                                        background: "#fff",
                                      }}
                                    >
                                      OR
                                    </span>
                                  </div>
                                  {loader && (
                                    <>
                                      <Upload {...props2}>
                                        <button
                                          to="create-job"
                                          style={{ width: "240px" }}
                                          className="btn btn-dark font-size-3 font-weight-normal  text-uppercase mt-2"
                                          onClick={() => {}}
                                        >
                                          Choose a Folder
                                        </button>
                                      </Upload>
                                    </>
                                  )}
                                </div>
                              }
                              placement="rightBottom"
                            >
                              <button
                                to="create-job"
                                style={{ width: "80%" }}
                                type="button"
                                className="btn btn-primary font-size-3 font-weight-normal  text-uppercase mt-0"
                                onClick={(e) => {}}
                              >
                                <i className="icon icon-attach-87 text-primary text-white font-weight-bold mr-3 font-size-5"></i>{" "}
                                Upload
                              </button>
                            </Popover>
                          </div>
                        </div>
                      </div>
                      <div className="button-block">
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            if (urlText !== "") {
                              uploadUrl(urlText);
                            }
                            seturlText("");
                          }}
                          className={
                            "btn  line-height-reset h-100 btn-submit w-100 text-uppercase" +
                            (urlText === "" ? " btn-light" : " btn-primary")
                          }
                        >
                          Proceed
                        </button>
                      </div>
                    </div>
                  </form>
                )}
              </div> */}
            </div>
            <div className="col-12 float-left pos-abs-tr z-index-n1 position-static position-lg-absolute ml-auto hero-image-positioning-2">
              <div className="image-group row justify-content-center">
                <div className="col-sm-12 col-10 mt-10 mt-lg-10">
                  <div
                    className="single-image"
                    data-aos="fade-left"
                    data-aos-duration="800"
                    data-aos-once="true"
                  >
                    <img src="/assets/images/pic1.jpg" alt="" width="80%" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-11 pt-lg-20 pb-7 pb-lg-18 ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-8 col-lg-6 col-xxl-5">
              <div className="text-center mb-8 mb-lg-18 px-xl-9 px-xxl-7">
                <h2 className="font-size-10 mb-6">
                  Let's know
                  <br className="d-none d-sm-block" /> about Sigmatext
                </h2>
                <p className="font-size-6 text-default-color px-xs-9 px-md-0">
                  An easy way to interact and chat with your documents and
                  webpages
                </p>
              </div>
            </div>
          </div>
          <div
            className="row justify-content-center"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-once="true"
          >
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
              <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                <div className="square-92 border border-primary border-5 rounded-4 text-primary font-size-8 mx-auto shadow-dodger mb-11">
                  1.
                </div>
                <div className="services-content">
                  <h3 className="font-size-6 mb-7">Why</h3>
                  <p className="font-size-4 text-default-color">
                    With the ability to chat with documents, users can
                    effortlessly obtain immediate answers to their most pressing
                    questions. No more reading through each and every page !
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
              <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                <div className="square-92 border border-primary border-5 rounded-4 text-primary font-size-8 mx-auto shadow-dodger mb-11">
                  2.
                </div>
                <div className="services-content">
                  <h3 className="font-size-6 mb-7">How</h3>
                  <p className="font-size-4 text-default-color">
                    Seamlessly upload any File and dive into immersive chats
                    with your document. Ask questions in your own words and
                    language. Ask anything you want in a format you need them!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 col-md-6 col-sm-8 col-xs-8">
              <div className="px-xl-7 px-xxl-12 pt-5 pb-3 pb-lg-9 text-center">
                <div className="square-92 border border-primary border-5 rounded-4 text-primary font-size-8 mx-auto shadow-7 mb-11">
                  3.
                </div>
                <div className="services-content">
                  <h3 className="font-size-6 mb-7">What</h3>
                  <p className="font-size-4 text-default-color">
                    Effortlessly generate concise summaries, inquire about the
                    topics that matter most to you, and even seamlessly
                    translate it into another language to captivate your
                    audience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="pt-13 pt-lg-18 pb-lg-18">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-xl-5 col-lg-5 col-md-8 col-xs-10"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="position-relative pr-xl-5 pr-md-15 pr-9">
                <img
                  src="assets/images/pic2.jpg"
                  alt=""
                  className="w-100 rounded-4"
                />
              </div>
            </div>
            <div
              className="col-xxl-6 col-xl-7 col-lg-7 col-md-8 col-sm-11"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="content-2 pl-xl-10 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-16 pr-xl-10 pr-xxl-0">
                <h2
                  className="font-size-8 mb-12 pr-xs-13  pr-md-0 pr-sm-8"
                  style={{ lineHeight: 1.4 }}
                >
                  Transform How You Work with{" "}
                  <span className="text-green">AI-Powered</span> Solutions
                </h2>
                <div className="media mb-11">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    1.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">
                      Effortless Document Management
                    </h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Organize and manage case documents seamlessly with our
                      intuitive platform. Access all your files in one place,
                      reducing time spent searching for information.
                    </p>
                  </div>
                </div>
                <div className="media mb-11">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    2.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">
                      AI-Driven Research and Insights
                    </h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Use AI to analyze documents and discover valuable insights
                      quickly. Enhance your legal research with AI that
                      understands and processes complex legal language.
                    </p>
                  </div>
                </div>
                <div className="media mb-11">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    3.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">
                      Collaborative Work Environment
                    </h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Work together with your team in a secure, cloud-based
                      environment. Share insights, annotate documents, and keep
                      everyone on the same page.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    4.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">
                      Enhanced Client Service
                    </h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Deliver faster, more accurate legal advice to your clients
                      with the power of AI at your fingertips.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-13 pt-lg-18 pb-lg-18">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-xxl-5 col-xl-6 col-lg-6 col-md-8 col-sm-11"
              data-aos="fade-left"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="content-2 pl-xl-10 d-flex flex-column justify-content-center h-100 pt-lg-0 pt-15 pr-xl-10 pr-xxl-0">
                <h2
                  className="font-size-8 mb-12 pr-xs-13  pr-md-0 pr-sm-8"
                  style={{ lineHeight: 1.4 }}
                >
                  Key Features Tailored for{" "}
                  <span className="text-green"> Legal Professionals</span>
                </h2>
                {/* <p className="text-default-color font-size-5 mb-7 mb-lg-12 pr-xxl-13 pr-lg-0 pr-md-10">
                  It supports diverse formats, offers transparent pricing,
                  enables voice-based chat, and allows custom document training.
                </p> */}
                <div className="media mb-11">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    1.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">Legal Researcher</h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Revolutionizing legal research with AI-powered insights
                      and unparalleled efficiency
                    </p>
                  </div>
                </div>
                <div className="media mb-11">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    2.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">Dictation</h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Effortless dictation for lawyers: Turn your voice into
                      accurate, actionable legal documents instantly
                    </p>
                  </div>
                </div>
                <div className="media mb-11">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    3.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">
                      Case-Level Document Management
                    </h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Easily categorize, store, and retrieve documents for each
                      case. Our system is designed to handle complex legal
                      documentation with ease.
                    </p>
                  </div>
                </div>
                <div className="media mb-11">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    4.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">
                      AI-Powered Chat and Assistance
                    </h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Chat with your documents using AI to uncover hidden
                      connections and insights. Ask questions and receive
                      answers based on your case data.
                    </p>
                  </div>
                </div>
                <div className="media">
                  <div className="media-icon fixedWidthNum px-6 py-5 border border-primary border-5 rounded-5 font-size-6 text-primary">
                    5.
                  </div>
                  <div className="media-body pl-7">
                    <h5 className="mb-5 text-primary">
                      Data Security and Compliance
                    </h5>
                    <p className="mb-0 font-size-4 text-default-color">
                      Ensure your documents are safe with top-tier security
                      protocols. Our platform is designed to comply with legal
                      industry standards.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-xl-6 col-lg-6 col-md-8 col-xs-10"
              data-aos="fade-right"
              data-aos-duration="800"
              data-aos-once="true"
            >
              <div className="position-relative pl-xl-5 pl-md-15 pl-9">
                <img
                  src="assets/images/pic3.jpg"
                  alt=""
                  className="w-100 rounded-4"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pt-18 pt-lg-18  pb-18 pb-lg-18 bg-primary mt-16 "
        id="calltoaction"
      >
        <div className="container">
          <div className="row pb-lg-6 justify-content-center mb-6">
            <div className="col-xxl-8 col-xl-9 col-lg-10 text-center">
              <h2 className="font-size-9 text-center mb-5 text-white">
                Ready to Elevate Your Legal Practice
              </h2>
              <p className="text-default-color font-size-6 px-5 px-md-10 px-lg-15 px-xl-24 px-xxl-22 text-center text-white">
                Experience the future of legal document management and research.
              </p>
              <button
                to="create-job"
                type="button"
                className="btn btn-white font-size-4 font-weight-normal  text-uppercase mt-0 px-8"
                onClick={(e) => {
                  if (isAuthenticated) {
                    history.push("/dashboard");
                  } else {
                    setshowModal(true);
                  }
                }}
              >
                Try Now
              </button>
              <button
                to="create-job"
                type="button"
                className="btn btn-outline-white ml-3 font-size-4 font-weight-normal  text-uppercase mt-0 px-8 "
                onClick={(e) => {
                  scrollToSection();
                }}
              >
                Request a Demo
              </button>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="pt-20 pt-lg-18 pb-lg-18 pb-14 mb-16 bg-light ">
        <div className="container mt-4 mb-8">
          <h2 className="text-center mb-10">What Our Clients Say</h2>
          <Slider {...settings}>
            <div style={{ padding: "20px" }}>
              <div className="d-flex justify-content-center  text-center">
                <div className="" style={{ maxWidth: "600px" }}>
                  <div className="">
                    <p className="card-text">
                      "This AI tool has transformed our document management
                      process. It's like having an extra team member dedicated
                      to research."
                    </p>
                    <div className="d-flex flex-column mb-4 align-items-center mt-4">
                      <div>
                        <h5 className="mb-0">John Doe</h5>
                        <small>Senior Partner, Law Firm</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex justify-content-center  text-center">
                <div className="" style={{ maxWidth: "600px" }}>
                  <div className="">
                    <p className="card-text">
                      "The AI insights have given us a competitive edge in case
                      analysis. Highly recommend it for any legal practice."
                    </p>
                    <div className="d-flex flex-column mb-4 align-items-center mt-4">
                      <div>
                        <h5 className="mb-0">Michael Lee</h5>
                        <small>Senior Partner, Law Firm</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section> */}
      <section
        className="pt-7 pt-lg-18 bg-default pb-2 pb-lg-18"
        id="contactus"
        ref={sectionRef}
      >
        <div className="container">
          <div className="row pb-lg-6 justify-content-center">
            <div className="col-xxl-8 col-xl-9 col-lg-10 ">
              <h2 className="font-size-9 text-center mb-5 text-primary">
                Contact Us
              </h2>
              <p className="text-default-color font-size-6 px-5 px-md-10 px-lg-15 px-xl-24 px-xxl-22 text-center">
                <strong>Connect with us for a brief discussion</strong>
              </p>
              <p className="text-default-color font-size-4 px-5 px-md-10 px-lg-15 px-xl-24 pb-10 px-xxl-22 text-center">
                If this sounds interesting for your organisation. Submit your
                application and let us know about your needs and ideas, and
                we'll get in touch if we can help you.
              </p>
              <div className="row pb-lg-6 justify-content-center">
                <div className="col-xxl-10 col-xl-10 col-lg-10 ">
                  <div className="bg-white px-9 pt-9 pb-7 shadow-8 rounded-4 contactBorder">
                    <form>
                      <div className="row">
                        <div className="col-12 mb-7">
                          <label className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset">
                            Your Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Jhon Doe"
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6 mb-7">
                          <label className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset">
                            E-mail
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="example@gmail.com"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-6 mb-7">
                          <label className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset">
                            Subject
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Special contract"
                            value={subject}
                            onChange={(e) => setsubject(e.target.value)}
                          />
                        </div>
                        <div className="col-lg-12 mb-7">
                          <label className="font-size-4 font-weight-semibold text-black-2 mb-5 line-height-reset">
                            Message
                          </label>
                          <textarea
                            name=""
                            id=""
                            placeholder="Type your message"
                            className="form-control h-px-144"
                            value={message}
                            onChange={(e) => setmessage(e.target.value)}
                          ></textarea>
                        </div>
                        <div className="col-lg-12 pt-4">
                          <button
                            type="button"
                            className="btn btn-primary text-uppercase w-100 h-px-48"
                            onClick={(e) => {
                              e.preventDefault();
                              sendMail();
                            }}
                          >
                            Send Now
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="mt-8">
                      <h3 className="font-size-4">Contact Information</h3>
                      <div className="media mb-2">
                        <div className="mr-6">
                          <i className="fas fa-phone-alt mt-2"></i>
                        </div>
                        <p className="font-size-4 mb-0">
                          <a
                            className="mb-0 font-size-4  fontBold400"
                            href="tel:+917972869198"
                          >
                            +91(INDIA)-7972869198
                          </a>
                        </p>
                      </div>
                      <div className="media mb-2">
                        <div className="mr-6">
                          <i className="fas fa-envelope mt-2"></i>
                        </div>
                        <p className="font-size-4 mb-0">
                          <a
                            className="mb-0 font-size-4 mb-2 fontBold400"
                            href="mailto:client.success@sigmatext.ai"
                          >
                            client.success@sigmatext.ai
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

Index.propTypes = {
  uploadInit: PropTypes.any,
  uploadUrl: PropTypes.any,
  isAuthenticated: PropTypes.any,
  isUploading: PropTypes.any,
  uploadInitMulti: PropTypes.func,
};
const mapStateToProps = (state) => ({
  isError: state.upload.isError,
  isErrorType: state.upload.isErrorType,
  errMessage: state.upload.errMessage,
  isAuthenticated: state.auth.isAuthenticated,
  isUploading: state.upload.isUploading,
});
export default connect(mapStateToProps, {
  uploadInit,
  uploadUrl,
  uploadInitMulti,
})(Index);
