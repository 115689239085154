import { Card, Input, message, Switch, Tooltip } from "antd";
import React, { useRef, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import store from "../../../../store";
import { CONVERSATION_DATA, TYPING_MESSAGE } from "../../../../Action/types";
import { useEffect } from "react";
import { chatFile, listSearchResults } from "../../../../Api/Upload";
import AudioRecorder from "./audioRecorder";
import { AudioPlayer } from "react-audio-player-component";

const formatText = (text) => {
  text = text.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

  const htmlString = text
    .replace(/\n/g, "<br />")
    .replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;") // Replace tab with four non-breaking spaces
    .replace(/ {2,}/g, (match) =>
      match
        .split("")
        .map(() => "&nbsp;")
        .join("")
    ); // Replace multiple spaces with non-breaking spaces
  return { __html: htmlString };
};

function extractAfterFirstQuestion(text) {
  const keyword = "Question: ";
  const firstIndex = text.indexOf(keyword);

  if (firstIndex === -1) {
    return ""; // Return an empty string if the keyword is not found
  }

  return text.slice(firstIndex + keyword.length).trim();
}

var promptTextGen = `Please ignore all previous instructions. You are an expert copywriter who writes detailed and thoughtful content.   I want you to write in the english language. Please use the details provided by the user. Please intersperse short and long sentences. Utilize uncommon terminology to enhance the originality of the content. Please format the content in a professional format. Do not self reference. Do not explain what you are doing.`;

const Index = ({
  data,
  chatFile,
  isError,
  isErrorType,
  errMessage,
  typing,
  conversationData,
  listSearchResults,
  SearchData,
  tourRef4,
  currentDirPath,
  setprompts,
}) => {
  useEffect(() => {
    if (isError && isErrorType === "SUCCESS") {
      message.success(errMessage);
    } else if (isError && isErrorType === "FAIL") {
      message.error(errMessage);
    }
  }, [isError, isErrorType, errMessage]);

  const [queryText, setqueryText] = useState("");
  const [isGeneric, setisGeneric] = useState(false);
  const currentCursor = useRef(null);
  const [audioData, setAudioData] = useState(null);

  useEffect(() => {
    if (currentCursor.current) {
      const inputElement = currentCursor.current.input;
      inputElement.setSelectionRange(queryText.length, queryText.length);
    }
  }, [queryText]);

  const submitText = async () => {
    if (!typing) {
      if (queryText !== "") {
        let qText = queryText;
        let data = { ...conversationData };
        data.conversation.push({
          date: new Date(),
          text: queryText,
          mode: "outgoing",
        });
        store.dispatch({
          type: CONVERSATION_DATA,
          payload: data,
        });
        chatFile(conversationData, qText, isGeneric, true, true, false);
        setqueryText("");
      }
      if (audioData !== null) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result.split(",")[1];
          let data = { ...conversationData };
          data.conversation.push({
            date: new Date(),
            text: base64Data,
            isAudio: true,
            mode: "outgoing",
          });
          store.dispatch({
            type: CONVERSATION_DATA,
            payload: data,
          });
          setTimeout(() => {
            store.dispatch({ type: TYPING_MESSAGE, payload: true });
          }, 500);
          chatFile(conversationData, base64Data, isGeneric, true, true, true);
          setAudioData(null);
        };
        reader.readAsDataURL(audioData.blob);
      }
    }
  };

  const heightRef = useRef(null);
  // const [searchEnabled, setsearchEnabled] = useState(false);

  useEffect(() => {
    if (heightRef.current !== null) {
      var objDiv = heightRef.current;
      objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }
  }, [conversationData]);

  useEffect(() => {
    if (heightRef.current !== null && typing) {
      var objDiv = heightRef.current;
      objDiv.scrollTop = objDiv.scrollHeight + 1000;
    }
  }, [typing]);

  //prompt textGeneration

  return (
    <div className="dashboard-main-container mt-9 mt-lg-9" id="dashboard-body">
      <div className="container position-relative">
        <span
          style={{
            position: "absolute",
            fontSize: "14px",
            fontWeight: "500",
            top: "-25px",
            left: "5%",
          }}
        >
          /My Files{currentDirPath}
          {conversationData === null
            ? ""
            : conversationData.is_file && "/" + conversationData.file}
        </span>
        <span
          style={{
            position: "absolute",
            fontSize: "14px",
            fontWeight: "500",
            top: "-25px",
            right: "5%",
          }}
        >
          <span className="mr-3">Use External Knowledge: </span>
          <Tooltip
            title={
              isGeneric
                ? "Disable External Knowledge base"
                : "Enable External Knowledge base"
            }
          >
            <Switch
              size="small"
              onChange={() => setisGeneric(!isGeneric)}
              checked={isGeneric}
            />
          </Tooltip>
        </span>
        <div className="row">
          <div className="col-11 m-auto heightFull">
            <div
              ref={tourRef4}
              className="col-12  px-14 inputBox position-relative"
              style={{ marginLeft: "-1rem" }}
            >
              <Input
                placeholder="Enter your query here..."
                ref={currentCursor}
                style={{ fontSize: "15px", height: "45px" }}
                suffix={
                  <SendOutlined
                    className=" text-primary"
                    style={{ fontSize: "18px", paddingLeft: "32px" }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      submitText();
                    }}
                  />
                }
                value={queryText}
                disabled={
                  data.length > 0
                    ? conversationData === null
                      ? true
                      : conversationData.is_folder
                      ? conversationData.value !== ""
                        ? conversationData.processedCount === 0 &&
                          conversationData.data.length === 0
                        : false
                      : !conversationData.isProcessed
                    : true
                }
                onChange={(e) => {
                  setqueryText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    submitText();
                  }
                }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "1px",
                  right: "82px",
                  zIndex: "999",
                  width: "40%",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setqueryText("");
                }}
              >
                <AudioRecorder
                  setqueryText={setqueryText}
                  audioData={audioData}
                  setAudioData={setAudioData}
                />
                {/* <SpeechRecognitionComponent setqueryText={setqueryText} /> */}
              </div>
            </div>
            <Card bodyStyle={{ paddingBottom: "4px" }}>
              <div className="chat-history" ref={heightRef}>
                {conversationData !== null && (
                  <ul className="m-b-0">
                    {conversationData.hasOwnProperty("conversation") &&
                      conversationData.conversation.map((ele, index) =>
                        ele.mode === "outgoing" ? (
                          <li className="clearfix" key={index}>
                            <div className="message-data text-right">
                              <span className="message-data-time">
                                {moment(ele.date).format("DD-MM-YYYY hh:mm")}
                              </span>
                              <img
                                src="https://bootdey.com/img/Content/avatar/avatar7.png"
                                alt="avatar"
                              />
                            </div>
                            <div className="message other-message float-right text-left">
                              {ele.hasOwnProperty("isAudio") && ele.isAudio ? (
                                <div className="AudioPlayerClasss">
                                  <AudioPlayer
                                    src={"data:audio/wav;base64," + ele.text}
                                    minimal={true}
                                    width={250}
                                    trackHeight={14}
                                    barWidth={3}
                                    gap={1}
                                    visualise={true}
                                    backgroundColor="transparent"
                                    barColor="#89b3f0"
                                    barPlayedColor="#1677ff"
                                    skipDuration={2}
                                    showLoopOption={true}
                                    showVolumeControl={true}
                                    hideSeekBar={true}
                                    hideSeekKnobWhenPlaying={true}
                                  />
                                </div>
                              ) : ele.text.includes(promptTextGen) ? (
                                extractAfterFirstQuestion(ele.text)
                              ) : (
                                ele.text
                              )}
                            </div>
                          </li>
                        ) : (
                          <li className="clearfix" key={index}>
                            <div className="message-data">
                              <span className="message-data-time">
                                {moment(ele.date).format("DD-MM-YYYY hh:mm")}
                              </span>
                            </div>
                            <div
                              className="message my-message"
                              dangerouslySetInnerHTML={formatText(ele.text)}
                            ></div>
                          </li>
                        )
                      )}
                    {typing && (
                      <li className="clearfix">
                        <div className="message-data">
                          <span className="message-data-time"></span>
                        </div>
                        <div
                          className="message my-message"
                          style={{ height: "30px" }}
                        >
                          <img
                            src="/assets/image/typing.gif"
                            style={{ height: "35px", marginTop: "-28px" }}
                            alt=""
                          />
                        </div>
                      </li>
                    )}
                    {data.length > 0 &&
                    conversationData.hasOwnProperty("isProcessed") &&
                    conversationData.is_folder
                      ? conversationData.processedCount === 0 && (
                          <li className="clearfix">
                            <div className="message-data">
                              <span className="message-data-time"></span>
                            </div>
                            <div className="message my-message">
                              Processing your document(s)...
                            </div>
                          </li>
                        )
                      : data.length > 0 &&
                        !conversationData.isProcessed && (
                          <li className="clearfix">
                            <div className="message-data">
                              <span className="message-data-time"></span>
                            </div>
                            <div className="message my-message">
                              Processing your document(s)...
                            </div>
                          </li>
                        )}
                  </ul>
                )}
              </div>
            </Card>
          </div>
        </div>
        <div className="row"></div>
      </div>
    </div>
  );
};

Index.propTypes = {
  isError: PropTypes.any,
  isErrorType: PropTypes.any,
  errMessage: PropTypes.any,
  isAuthenticated: PropTypes.any,
  data: PropTypes.any.isRequired,
  typing: PropTypes.any,
  conversationData: PropTypes.any,
  listSearchResults: PropTypes.any,
  SearchData: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isError: state.upload.isErrorChat,
  isErrorType: state.upload.isErrorTypeChat,
  errMessage: state.upload.errMessageChat,
  data: state.upload.data,
  typing: state.upload.typing,
  isAuthenticated: state.auth.isAuthenticated,
  conversationData: state.upload.conversationData,
  SearchData: state.upload.SearchData,
});
export default connect(mapStateToProps, { chatFile, listSearchResults })(Index);
