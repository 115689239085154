import "./App.css";
import { Route, BrowserRouter as Router } from "react-router-dom";
import LawyersLanding from "./components/pages/LawyersLanding";
import NewLandingPage from "./components/pages/newLanding";
// import LandingPageNew from './components/pages/Landing/LandingPageNew';
import About from "./components/pages/Landing/AboutUs";
// import Dashboard from "./components/pages/Dashboard/oldDashboard";
import Education from "./components/pages/Dashboard/Education";
import Dashboard from "./components/pages/Dashboard2";
// new dashboard code ------------------------------
import NewDashboard from "./components/pages/NewDashboard";
import Chat from "./components/pages/NewDashboard/Chat";
import TextGeneration from "./components/pages/NewDashboard/TextGeneration";
import SideBarChat from "./components/base/SideBarChat";
// ------------------------------------------------
import TopBar from "./components/base/Header";
import SideBar from "./components/base/SideBar";
import Footer from "./components/base/Footer";
import Login from "./components/Models/Login";
import Forgot from "./components/Models/Forgot";
import Reset from "./components/Models/Reset";
import Pricing from "./components/Models/Pricing";
import PrivacyPolicy from "./components/pages/Landing/PrivacyPolicy";
import { WarningOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import setAuthToken from "./util/setAuthToken";
import { connect } from "react-redux";
import { listChats } from "./Api/Upload";
import Register from "./components/Models/Register";
import { checkToken, checkTokenGet, loginTemp } from "./Api/Auth";
import Contact from "./components/pages/Landing/Contact";
import Private from "./components/pages/Landing/Private";
import Faqs from "./components/pages/Landing/Faqs";
import LLM from "./components/pages/Landing/LLM";
import Prompts from "./components/pages/Landing/Prompts";
import { Alert, Progress, Space, Tooltip, Tour } from "antd";
import store from "./store";
import { UPLOADING_MULTI_FILES } from "./Action/types";
import PromptsChat from "./components/pages/PromptsChat";

function App({
  isAuthenticated,
  listChats,
  loginTemp,
  isGuest,
  checkToken,
  checkTokenGet,
  multiFileStatus,
  succeedCount,
  failedCount,
  totalCount,
  token,
}) {
  const [showModal, setshowModal] = useState(false);
  const [showModal1, setshowModal1] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  const [showModal3, setshowModal3] = useState(false);
  const [showModal4, setshowModal4] = useState(false);
  const [resetToken, setresetToken] = useState(null);
  const [prompts, setprompts] = useState(false);
  const [selectedPrice, setselectedPrice] = useState("");
  const [selectePrompts, setselectePrompts] = useState("");
  const [selectePromptsDesc, setselectePromptsDesc] = useState("");
  const [currentDirPath, setcurrentDirPath] = useState("");

  const [openPopover, setopenPopover] = useState(false);
  const [open, setOpen] = useState(false);

  const [selectedConversation, setselectedConversation] = useState(null);
  const [refreshConversations, setrefreshConversations] = useState(false);

  const steps = [
    {
      title: "File Upload",
      description: (
        <div>
          <img
            src="/assets/image/uploadS.png"
            alt=""
            style={{ width: "100%" }}
          />
          <p
            style={{ fontWeight: "500", fontSize: "15px", lineHeight: "20px" }}
          >
            You can choose either of the available upload type to get started
            with your document processing
          </p>
          <ul>
            <li>
              You can directly paste the link and proceed with the document
              processing.
            </li>
            <li>
              You can select single file to proceed with the document processing
            </li>
            <li>
              You can directly upload the folder to proceed with multiple file
              in one go.
            </li>
          </ul>
        </div>
      ),
      target: () => tourRef1.current,
    },
    {
      title: "Files & Folders",
      description: "Here you can find all the files and folders uploaded",
      target: () => tourRef2.current,
    },
    {
      title: "Interaction Mode",
      description: "Switch from chat to search mode and vice versa",
      target: () => tourRef3.current,
    },
    {
      title: "Query Text",
      description: (
        <div>
          <p style={{ fontSize: "14px" }}>
            Enter your text here to interact with selected folder's documents /
            Single document
          </p>
          <p style={{ fontWeight: "500", fontSize: "14px" }}>
            Reasons for disabled interface
          </p>
          <ul>
            <li>No documents under the selected folder</li>
            <li>No folder or file selected</li>
            <li>Files / Files inside the folder is in processing </li>
          </ul>
        </div>
      ),
      target: () => tourRef4.current,
    },
  ];

  useEffect(() => {
    setInterval(() => {
      checkToken();
    }, 300000);
  }, [checkToken]);

  useEffect(() => {
    if (sessionStorage.token) {
      checkTokenGet();
    } else {
      loginTemp();
    }
  }, [checkTokenGet, loginTemp]);

  useEffect(() => {
    if ((isAuthenticated || isGuest) && token !== null) {
      setAuthToken("Bearer " + token);
    }
  }, [isAuthenticated, isGuest, token]);

  useEffect(() => {
    if (isAuthenticated) {
      listChats();
    } else if (isGuest) {
      if (sessionStorage.token) {
        listChats();
      } else {
        loginTemp();
      }
    }
  }, [isAuthenticated, listChats, loginTemp, isGuest, checkTokenGet]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const resetToken = urlParams.get("reset_password");
    if (resetToken !== null) {
      setresetToken(resetToken);
      setshowModal3(true);
    }
  }, []);

  //tour refs
  const tourRef1 = useRef(null);
  const tourRef2 = useRef(null);
  const tourRef3 = useRef(null);
  const tourRef4 = useRef(null);

  return (
    <div className="site-wrapper overflow-hidden bg-default-2 min-h-100vh">
      <Router>
        <Route exact path="/">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <LawyersLanding
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setshowModal={setshowModal}
          />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/education">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <NewLandingPage
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setshowModal={setshowModal}
          />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/privacy-policy">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <PrivacyPolicy />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/contact-us">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <Contact />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/about-us">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <About />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/private-gpt">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <Private />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/LLM">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <LLM />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/prompts">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <Prompts />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/education/dashboard">
          <SideBar
            setOpen={setOpen}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
            tourRef1={tourRef1}
            tourRef2={tourRef2}
            openPopover={openPopover}
            setopenPopover={setopenPopover}
            prompts={false}
            selectePrompts={selectePrompts}
            selectePromptsDesc={selectePromptsDesc}
            setselectePrompts={setselectePrompts}
            setselectePromptsDesc={setselectePromptsDesc}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
          />
          <Education
            tourRef3={tourRef3}
            tourRef4={tourRef4}
            prompts={prompts}
            selectePromptsDesc={selectePromptsDesc}
            selectePrompts={selectePrompts}
            setprompts={setprompts}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
          />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        {/* new dashboard code here */}
        <Route exact path="/dashboard">
          <SideBar
            setOpen={setOpen}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
            tourRef1={tourRef1}
            tourRef2={tourRef2}
            openPopover={openPopover}
            setopenPopover={setopenPopover}
            prompts={false}
            selectePrompts={selectePrompts}
            selectePromptsDesc={selectePromptsDesc}
            setselectePrompts={setselectePrompts}
            setselectePromptsDesc={setselectePromptsDesc}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
            freeze={true}
          />
          <NewDashboard />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/dashboard/chat">
          <SideBar
            setOpen={setOpen}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
            tourRef1={tourRef1}
            tourRef2={tourRef2}
            openPopover={openPopover}
            setopenPopover={setopenPopover}
            prompts={false}
            selectePrompts={selectePrompts}
            selectePromptsDesc={selectePromptsDesc}
            setselectePrompts={setselectePrompts}
            setselectePromptsDesc={setselectePromptsDesc}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
          />
          <Chat
            tourRef3={tourRef3}
            tourRef4={tourRef4}
            prompts={prompts}
            selectePromptsDesc={selectePromptsDesc}
            selectePrompts={selectePrompts}
            setprompts={setprompts}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
          />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/dashboard/text-generation">
          <SideBarChat
            setOpen={setOpen}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
            tourRef1={tourRef1}
            tourRef2={tourRef2}
            openPopover={openPopover}
            setopenPopover={setopenPopover}
            prompts={false}
            selectePrompts={selectePrompts}
            selectePromptsDesc={selectePromptsDesc}
            setselectePrompts={setselectePrompts}
            setselectePromptsDesc={setselectePromptsDesc}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
            selectedConversation={selectedConversation}
            setselectedConversation={setselectedConversation}
            refreshConversations={refreshConversations}
            setrefreshConversations={setrefreshConversations}
          />
          <TextGeneration
            tourRef3={tourRef3}
            tourRef4={tourRef4}
            prompts={prompts}
            selectePromptsDesc={selectePromptsDesc}
            selectePrompts={selectePrompts}
            setprompts={setprompts}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
            selectedConversation={selectedConversation}
            setselectedConversation={setselectedConversation}
            refreshConversations={refreshConversations}
            setrefreshConversations={setrefreshConversations}
          />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        {/* Old Dashboard Route */}
        <Route exact path="/dashboard2">
          <SideBar
            setOpen={setOpen}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
            tourRef1={tourRef1}
            tourRef2={tourRef2}
            openPopover={openPopover}
            setopenPopover={setopenPopover}
            prompts={false}
            selectePrompts={selectePrompts}
            selectePromptsDesc={selectePromptsDesc}
            setselectePrompts={setselectePrompts}
            setselectePromptsDesc={setselectePromptsDesc}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
          />
          <Dashboard
            tourRef3={tourRef3}
            tourRef4={tourRef4}
            prompts={prompts}
            selectePromptsDesc={selectePromptsDesc}
            selectePrompts={selectePrompts}
            setprompts={setprompts}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
          />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/text-analyse">
          <SideBar
            setOpen={setOpen}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
            tourRef1={tourRef1}
            tourRef2={tourRef2}
            tourRef3={tourRef3}
            openPopover={openPopover}
            setopenPopover={setopenPopover}
            prompts={true}
            selectePrompts={selectePrompts}
            selectePromptsDesc={selectePromptsDesc}
            setselectePrompts={setselectePrompts}
            setselectePromptsDesc={setselectePromptsDesc}
            currentDirPath={currentDirPath}
            setcurrentDirPath={setcurrentDirPath}
          />
          <PromptsChat
            selectePrompts={selectePrompts}
            selectePromptsDesc={selectePromptsDesc}
            setselectePrompts={setselectePrompts}
            setselectePromptsDesc={setselectePromptsDesc}
          />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
        <Route exact path="/faqs">
          <TopBar
            setOpen={setOpen}
            setshowModal3={setshowModal3}
            setshowModal={setshowModal}
            setshowModal1={setshowModal1}
            setshowModal2={setshowModal2}
          />
          <Faqs />
          <Footer />
          <Login
            showModal={showModal}
            setModal={setshowModal}
            setshowModal2={setshowModal2}
            setshowModal3={setshowModal3}
            setshowModal4={setshowModal4}
          />
          <Reset
            showModal={showModal3}
            setModal={setshowModal3}
            token={resetToken}
            settoken={setresetToken}
            setshowModal2={setshowModal4}
          />
          <Forgot
            setshowModal1={setshowModal}
            showModal={showModal4}
            setModal={setshowModal4}
            setshowModal3={setshowModal3}
          />
          <Register
            showModal={showModal2}
            setModal={setshowModal2}
            setshowModal={setshowModal}
          />
          <Pricing
            showModal={showModal1}
            selectedPrice={selectedPrice}
            setselectedPrice={setselectedPrice}
            setModal={setshowModal1}
          />
        </Route>
      </Router>
      <Tour
        open={open}
        onClose={() => {
          setOpen(false);
          localStorage.setItem("isTourDone", true);
        }}
        steps={steps}
      />
      {multiFileStatus && (
        <div
          className="shadow "
          style={{
            width: "300px",
            position: "fixed",
            top: "0px",
            right: "0",
            borderRadius: "20px",
            zIndex: 999,
          }}
        >
          <Alert
            description={
              <>
                <span>
                  <Progress
                    type="circle"
                    percent={((succeedCount / totalCount) * 100).toFixed(0)}
                    size={40}
                  />
                </span>
                <span className="ml-3" style={{ fontWeight: "500" }}>
                  {succeedCount === totalCount
                    ? "Completed"
                    : succeedCount + " out of " + totalCount + " Complete"}
                </span>
                {succeedCount === totalCount && failedCount > 0 && (
                  <Tooltip
                    placement="top"
                    title={failedCount + " File(s) Failed"}
                  >
                    <span className=" text-danger ml-3">
                      <WarningOutlined />
                    </span>
                  </Tooltip>
                )}
              </>
            }
            onClose={() => {
              store.dispatch({
                type: UPLOADING_MULTI_FILES,
                payload: {
                  status: false,
                  succeedCount: 0,
                  failedCount: 0,
                  totalCount: 0,
                },
              });
            }}
            type="info"
            action={<Space direction="vertical"></Space>}
            closable={succeedCount === totalCount}
          />
        </div>
      )}
      {!isAuthenticated && !isGuest && (
        <div
          style={{
            background: "rgba(255, 255, 255, 0.1)",
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: 9999,
          }}
        ></div>
      )}
    </div>
  );
}

App.propTypes = {
  isAuthenticated: PropTypes.any,
  isGuest: PropTypes.any,
  multiFileStatus: PropTypes.any,
  succeedCount: PropTypes.any,
  failedCount: PropTypes.any,
  totalCount: PropTypes.any,
  token: PropTypes.any,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isGuest: state.auth.isGuest,
  multiFileStatus: state.upload.multiFileStatus,
  succeedCount: state.upload.succeedCount,
  failedCount: state.upload.failedCount,
  totalCount: state.upload.totalCount,
  token: state.auth.token,
});
export default connect(mapStateToProps, {
  listChats,
  loginTemp,
  checkTokenGet,
  checkToken,
})(App);
