import { Col, Modal, Row, Skeleton, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FolderFilled,
  FilePdfOutlined,
  FileWordOutlined,
  FileTextOutlined,
  FileUnknownOutlined,
} from "@ant-design/icons";

const Index = ({
  path,
  setpath,
  fileList,
  selectFile,
  fileModel,
  setfileModel,
  setloading,
  loading,
  getFileList,
}) => {
  const [selectedFile, setselectedFile] = useState(null);
  useEffect(() => {
    if (fileModel) {
      setTimeout(() => {
        setloading(false);
      }, 2000);
    }
  }, [fileModel, setloading]);

  const getFileIcon = (fileName, is_folder) => {
    const extension = is_folder
      ? "folder"
      : fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return (
          <FilePdfOutlined
            style={{ fontSize: "50px" }}
            className="text-danger"
          />
        );
      case "doc":
      case "docx":
        return (
          <FileWordOutlined
            style={{ fontSize: "50px" }}
            className="text-secondary"
          />
        );
      case "txt":
        return (
          <FileTextOutlined
            style={{ fontSize: "50px" }}
            className="text-black-50"
          />
        );
      case "folder": // Assuming you want to display folders too
        return (
          <FolderFilled style={{ fontSize: "50px" }} className="text-primary" />
        );
      default:
        return (
          <FileUnknownOutlined
            style={{ fontSize: "50px" }}
            className="text-black"
          />
        );
    }
  };

  return (
    <Modal
      title="Select File"
      size="large"
      open={fileModel}
      onOk={() => {
        if (selectedFile.is_folder) {
          let data = [...path];
          setpath([...data, selectedFile.name]);
          getFileList([...data, selectedFile.name]);
        } else {
          selectFile(selectedFile);
          setloading(true);
          setfileModel();
          setselectedFile(null);
          setpath([]);
        }
      }}
      okText={"Open"}
      onCancel={() => {
        setloading(true);
        setfileModel();
        setselectedFile(null);
        setpath([]);
      }}
      loading={loading}
      width={900}
      bodyStyle={{ maxHeight: "60vh", minHeight: "40vh" }}
    >
      {loading && <Skeleton active />}
      {!loading && (
        <Row gutter={18}>
          <Col sm={24} className="mb-3">
            <Link
              to={"#!"}
              style={{ color: "black" }}
              className={
                " font-weight-bold " +
                (path.length > 0 ? "text-black" : "text-primary")
              }
              onClick={(e) => {
                e.preventDefault();
                setpath([]);
                getFileList([]);
              }}
            >
              Home <span className="text-black">/ </span>
            </Link>
            {path.map((item, index) => (
              <>
                {" "}
                <Link
                  to={"#!"}
                  style={{ color: "black" }}
                  key={index}
                  className={
                    " font-weight-bold " +
                    (path.length === index + 1 ? "text-primary" : "text-black")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    let data = [...path];
                    data = data.slice(0, index + 1);
                    setpath(data);
                    getFileList(data);
                  }}
                >
                  {item} <span className="text-black">/ </span>{" "}
                </Link>
              </>
            ))}
          </Col>
          {fileList
            .sort((a, b) => {
              return a.is_folder === b.is_folder ? 0 : a.is_folder ? -1 : 1;
            })
            .map((item, index) => (
              <Tooltip title={item.name} placement="bottom" key={index}>
                <Col
                  className={
                    "fileManagerBox " +
                    (selectedFile !== null
                      ? selectedFile.id === item.id
                        ? "active"
                        : selectedFile.name === item.name
                        ? "active"
                        : ""
                      : "")
                  }
                  key={index}
                  onClick={() => {
                    setselectedFile(item);
                  }}
                  onDoubleClick={() => {
                    if (item.is_folder) {
                      setpath([...path, item.name]);
                      getFileList([...path, item.name]);
                    } else {
                      selectFile(item);
                      setloading(true);
                      setfileModel();
                      setselectedFile(null);
                      setpath([]);
                    }
                  }}
                >
                  {getFileIcon(item.name, item.is_folder)}
                  <p className="file-name">{item.name}</p>
                </Col>
              </Tooltip>
            ))}
        </Row>
      )}
    </Modal>
  );
};

export default Index;
