import {
  ALERT_UPLOAD,
  BACKEND_URL,
  RESET_UPLOAD,
  SET_FILE_DATA,
  UPLOADING_FILE,
  ALERT_CHAT,
  RESET_CHAT,
  TRIGGER_CONVERSATION,
  TYPING_MESSAGE,
  UPLOADING_MULTI_FILES,
  CONVERSATION_DATA,
  SEARCH_DATA,
} from "../Action/types";
import axios from "axios";
import store from "../store";
import { checkToken } from "./Auth";

export const uploadUrl = (url, path) => async (dispatch) => {
  dispatch({
    type: UPLOADING_FILE,
    payload: true,
  });
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "POST",
    url: BACKEND_URL + "aws/s3",
    data: { file_name: url, file_path: path + "/" },
    headers: config,
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(
          uploadFinalize(response.data.file_url_data.file_id, true, url)
        );
      } else {
        dispatch({
          type: ALERT_UPLOAD,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: response.data.error_message,
          },
        });
        dispatch({
          type: UPLOADING_FILE,
          payload: false,
        });
        setTimeout(() => {
          dispatch({
            type: RESET_UPLOAD,
          });
        }, 200);
      }
    })
    .catch((error) => {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 401) {
          dispatch(checkToken());
        }
      }
      dispatch({
        type: UPLOADING_FILE,
        payload: false,
      });
      dispatch({
        type: ALERT_UPLOAD,
        payload: {
          isError: true,
          isErrorType: "FAIL",
          errMessage: error.hasOwnProperty("response")
            ? error.response.data.hasOwnProperty("error_message")
              ? error.response.data.error_message
              : "Please try again later!"
            : "Please try again later!",
        },
      });
      setTimeout(() => {
        dispatch({
          type: RESET_UPLOAD,
        });
      }, 200);
    });
};

export const uploadInit = (file, currentDirPath) => async (dispatch) => {
  dispatch({
    type: UPLOADING_FILE,
    payload: true,
  });
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "POST",
    url: BACKEND_URL + "aws/s3",
    data: { file_name: file.name, file_path: currentDirPath + "/" + file.name },
    headers: config,
  })
    .then((response) => {
      if (response.data.success) {
        dispatch(
          s3upload(
            file,
            response.data.file_url_data.s3_url_data.url,
            response.data.file_url_data.s3_url_data.fields.key,
            response.data.file_url_data.s3_url_data.fields["x-amz-algorithm"],
            response.data.file_url_data.s3_url_data.fields["x-amz-credential"],
            response.data.file_url_data.s3_url_data.fields["x-amz-date"],
            response.data.file_url_data.s3_url_data.fields["policy"],
            response.data.file_url_data.s3_url_data.fields["x-amz-signature"],
            response.data.file_url_data["file_id"],
            file.name
          )
        );
      } else {
        dispatch({
          type: ALERT_UPLOAD,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: response.data.error_message,
          },
        });
        dispatch({
          type: UPLOADING_FILE,
          payload: false,
        });
        setTimeout(() => {
          dispatch({
            type: RESET_UPLOAD,
          });
        }, 200);
      }
    })
    .catch((error) => {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 401) {
          dispatch(checkToken());
        }
      }
      dispatch({
        type: UPLOADING_FILE,
        payload: false,
      });
      dispatch({
        type: ALERT_UPLOAD,
        payload: {
          isError: true,
          isErrorType: "FAIL",
          errMessage: error.hasOwnProperty("response")
            ? error.response.data.hasOwnProperty("error_message")
              ? error.response.data.error_message
              : "Please try again later!"
            : "Please try again later!",
        },
      });
      setTimeout(() => {
        dispatch({
          type: RESET_UPLOAD,
        });
      }, 200);
    });
};

export const s3upload =
  (
    file,
    url,
    key,
    algorithm,
    credential,
    date,
    policy,
    signature,
    file_id,
    file_name,
    isReturn = false
  ) =>
  async (dispatch) => {
    var FormData = require("form-data");
    let formdata = new FormData();
    formdata.append("key", key);
    formdata.append("x-amz-algorithm", algorithm);
    formdata.append("x-amz-credential", credential);
    formdata.append("x-amz-date", date);
    formdata.append("policy", policy);
    formdata.append("x-amz-signature", signature);
    formdata.append("file", file);
    formdata.append("file_id", file_id);
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.upload.onprogress = function (pe) {
      //     dispatch({
      //       type: IN_PROGRESS_RAW_UPLOAD,
      //       payload: (pe.loaded / pe.total) * 100,
      //     });
    };
    xhr.addEventListener("readystatechange", async function () {
      if (this.readyState === 4) {
        if (this.status === 204 || this.status === 200) {
          if (isReturn) {
            let data = sessionStorage.getItem("file_url_data")
              ? JSON.parse(sessionStorage.getItem("file_url_data"))
              : [];
            data = data.map((ele) => {
              if (ele.file_id === file_id) {
                return {
                  ...ele,
                  is_uploaded: 1,
                };
              } else {
                return ele;
              }
            });
            sessionStorage.setItem("file_url_data", JSON.stringify(data));
            return;
          }
          dispatch(uploadFinalize(file_id, true, file_name));
        } else {
          let data = sessionStorage.getItem("file_url_data")
            ? JSON.parse(sessionStorage.getItem("file_url_data"))
            : [];
          data = data.map((ele) => {
            if (ele.file_id === file_id) {
              return {
                ...ele,
                is_uploaded: 2,
              };
            } else {
              return ele;
            }
          });
          sessionStorage.setItem("file_url_data", JSON.stringify(data));
          dispatch({
            type: UPLOADING_FILE,
            payload: false,
          });
          dispatch({
            type: ALERT_UPLOAD,
            payload: {
              isError: true,
              isErrorType: "FAIL",
              errMessage: "File uploading failed",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_UPLOAD,
            });
          }, 200);
        }
      }
    });
    xhr.open("POST", url);
    xhr.send(formdata);
  };

export const uploadFinalize =
  (file_id, isUploaded, file_name) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "PUT",
      url: BACKEND_URL + "aws/s3",
      data: { file_id, is_uploaded: isUploaded },
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(listChats(null));
          dispatch({
            type: UPLOADING_FILE,
            payload: false,
          });
          dispatch({
            type: ALERT_UPLOAD,
            payload: {
              isError: true,
              isErrorType: "SUCCESS",
              errMessage: "File Uploading Completed...",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_UPLOAD,
            });
          }, 200);
        } else {
          dispatch({
            type: UPLOADING_FILE,
            payload: false,
          });
          dispatch({
            type: ALERT_UPLOAD,
            payload: {
              isError: true,
              isErrorType: "FAIL",
              errMessage: response.data.error_message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_UPLOAD,
            });
          }, 200);
        }
      })
      .catch((error) => {
        dispatch({
          type: UPLOADING_FILE,
          payload: false,
        });
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            dispatch(checkToken());
          }
        }
        dispatch({
          type: ALERT_UPLOAD,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: error.hasOwnProperty("response")
              ? error.response.data.hasOwnProperty("error_message")
                ? error.response.data.error_message
                : "Please try again later!"
              : "Please try again later!",
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_UPLOAD,
          });
        }, 200);
      });
  };

export const chatFile =
  (
    conversationData,
    text,
    isGeneric,
    is_chat,
    is_prompt = true,
    audio_flag = false
  ) =>
  async (dispatch) => {
    dispatch({ type: TYPING_MESSAGE, payload: true });
    let config = {
      "Content-Type": audio_flag ? "application/json " : "application/json",
    };
    let body = {};
    if (audio_flag) {
      body.audio_flag = audio_flag;
      body.audio_file = text;
      // body.append("audio_file", text);
      // body.append("audio_flag", audio_flag);

      if (conversationData.is_file) {
        body.file_id = conversationData.value;
        // body.append("file_id", conversationData.value);
      } else {
        body.current_file_path = conversationData.value;
        // body.append("current_file_path", conversationData.value);
      }
      if (body.current_file_path === "") {
        // body.append("current_file_path", "/");
        body.current_file_path = "/";
      }
    } else {
      body = { text };
      if (conversationData.is_file) {
        body.file_id = conversationData.value;
      } else {
        body.current_file_path = conversationData.value;
      }
      if (body.current_file_path === "") {
        body.current_file_path = "/";
      }
    }
    body.is_generic = isGeneric;
    body.is_prompt = is_prompt;
    body.is_chat = is_chat;
    await axios({
      method: "POST",
      url: BACKEND_URL + "chat/conversation",
      data: body,
      headers: config,
      responseType: audio_flag ? "json" : "json",
    })
      .then((response) => {
        if (response.data.success) {
          var data = store.getState().upload.conversationData;
          data.conversation.push({
            date: new Date(response.data.conversation.bot_response.created_at),
            text: response.data.conversation.bot_response.text,
            mode: "incoming",
          });
          dispatch({
            type: CONVERSATION_DATA,
            payload: {},
          });
          dispatch({
            type: CONVERSATION_DATA,
            payload: data,
          });
          // dispatch({ type: SET_FILE_ID, payload: file_id });
          // var data = store.getState().upload.data;
          // var selected = data.filter((ele) => ele.isSelected)[0];
          // selected.data.push({
          //   date: new Date(response.data.conversation.bot_response.created_at),
          //   text: response.data.conversation.bot_response.text,
          //   mode: 'incoming',
          // });
          // var dataz = data.map((ele) => {
          //   if (ele.isSelected) {
          //     return selected;
          //   } else {
          //     return ele;
          //   }
          // });
          // sessionStorage.setItem('history', JSON.stringify(dataz));
          // store.dispatch({ type: SET_FILE_DATA, payload: dataz });
          dispatch({ type: TYPING_MESSAGE, payload: false });
        } else {
          dispatch({ type: TYPING_MESSAGE, payload: false });
          dispatch({
            type: ALERT_CHAT,
            payload: {
              isError: true,
              isErrorType: "FAIL",
              errMessage: response.data.error_message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_CHAT,
            });
          }, 200);
        }
      })
      .catch((error) => {
        dispatch({ type: TYPING_MESSAGE, payload: false });
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            dispatch(checkToken());
          }
        }
        dispatch({
          type: ALERT_CHAT,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: error.hasOwnProperty("response")
              ? error.response.data.hasOwnProperty("error_message")
                ? error.response.data.error_message
                : "Please try again later!"
              : "Please try again later!",
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_CHAT,
          });
        }, 200);
      });
  };

export const listChats =
  (file = null) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "GET",
      url: BACKEND_URL + "aws/s3?order_by=1&current_file_path= ",
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          if (file !== null) {
            if (
              response.data.file_data
                .filter((ele) => !ele.is_folder)
                .filter((ele) => ele.id === file && !ele.is_file_processed)
                .length > 0
            ) {
              return;
            } else {
              dispatch({
                type: ALERT_UPLOAD,
                payload: {
                  isError: true,
                  isErrorType: "SUCCESS",
                  errMessage: "File Uploaded Successfully",
                },
              });
              setTimeout(() => {
                dispatch({
                  type: RESET_UPLOAD,
                });
              }, 200);
            }
          }
          dispatch({
            type: UPLOADING_FILE,
            payload: false,
          });
          let history = [];
          response.data.file_data.forEach((ele) => {
            history.push({
              ...ele,
              file: ele.name,
              data: [],
              page_limit: ele.page_limit,
              isSelected: false,
              file_id: ele.id,
              is_folder: ele.is_folder,
              totalCount: ele.is_folder ? ele.total_files_count : 0,
              processedCount: ele.is_folder ? ele.files_processed_count : 0,
              folderData: [],
              is_folder_open: false,
              isSummary:
                ele.is_summary_generated === null
                  ? false
                  : ele.is_summary_generated,
              isProcessed:
                ele.is_file_processed === null ? true : ele.is_file_processed,
            });
          });

          sessionStorage.setItem("history", JSON.stringify(history));
          dispatch({ type: SET_FILE_DATA, payload: history });
          dispatch({ type: TRIGGER_CONVERSATION, payload: true });
        } else {
          dispatch({
            type: ALERT_UPLOAD,
            payload: {
              isError: true,
              isErrorType: "FAIL",
              errMessage: response.data.error_message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_UPLOAD,
            });
          }, 200);
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            dispatch(checkToken());
          }
        }
        dispatch({
          type: ALERT_UPLOAD,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: error.hasOwnProperty("response")
              ? error.response.data.hasOwnProperty("error_message")
                ? error.response.data.error_message
                : "Please try again later!"
              : "Please try again later!",
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_UPLOAD,
          });
        }, 200);
      });
  };

export const listChatsRepeat =
  (file = null) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "GET",
      url: BACKEND_URL + "aws/s3?order_by=1&current_file_path= ",
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          var data = response.data.file_data;
          data = data.map((ele) => {
            return {
              ...ele,
              isSummary:
                ele.is_summary_generated === null
                  ? false
                  : ele.is_summary_generated,
              isProcessed:
                ele.is_file_processed === null ? true : ele.is_file_processed,
              is_folder: ele.is_folder,
              totalCount: ele.is_folder ? ele.total_files_count : 0,
              processedCount: ele.is_folder ? ele.files_processed_count : 0,
              folderData: [],
              is_folder_open: false,
            };
          });
          sessionStorage.setItem("history", JSON.stringify(data));
          dispatch({ type: SET_FILE_DATA, payload: data });
        } else {
          dispatch({
            type: ALERT_UPLOAD,
            payload: {
              isError: true,
              isErrorType: "FAIL",
              errMessage: response.data.error_message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_UPLOAD,
            });
          }, 200);
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            dispatch(checkToken());
          }
        }
        dispatch({
          type: ALERT_UPLOAD,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: error.hasOwnProperty("response")
              ? error.response.data.hasOwnProperty("error_message")
                ? error.response.data.error_message
                : "Please try again later!"
              : "Please try again later!",
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_UPLOAD,
          });
        }, 200);
      });
  };

export const listSearchResults =
  (conversationData, search) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    let url = BACKEND_URL + "search/doc?search_term=" + search;
    if (conversationData.is_file) {
      url += "&file_id=" + conversationData.value;
    } else {
      url += "&current_file_path=" + conversationData.value;
    }
    await axios({
      method: "GET",
      url: url,
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          var data = response.data.results;
          dispatch({ type: SEARCH_DATA, payload: data });
        } else {
          dispatch({ type: SEARCH_DATA, payload: [] });
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            dispatch(checkToken());
          }
        }
        dispatch({ type: SEARCH_DATA, payload: [] });
      });
  };

export const listChatHistory =
  (value, is_file, isProcessed, isSummary, item) => async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    if (value === "") {
      value = "/";
    }
    let url =
      BACKEND_URL +
      "chat/conversation?" +
      (is_file ? "file_id=" + value : "current_file_path=" + value);
    await axios({
      method: "GET",
      url: url,
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          var data = response.data.conversation.reverse();
          var newSet = [];
          var isUser = true;
          data.forEach((ele) => {
            newSet.push({
              date: new Date(ele.created_at),
              text: ele.user.is_bot
                ? ele.text
                : ele.audio_file !== null
                ? ele.audio_file
                : ele.text,
              isAudio: ele.audio_file !== null,
              mode: ele.user.is_bot ? "incoming" : "outgoing",
            });
            isUser = !isUser;
          });
          dispatch({
            type: CONVERSATION_DATA,
            payload: {
              ...item,
              value: value,
              is_file: is_file,
              conversation: newSet,
              isProcessed: isProcessed,
              isSummary: isSummary,
            },
          });
          // let history = store.getState().upload.data;
        } else {
          dispatch({
            type: ALERT_UPLOAD,
            payload: {
              isError: true,
              isErrorType: "FAIL",
              errMessage: response.data.error_message,
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_UPLOAD,
            });
          }, 200);
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            dispatch(checkToken());
          }
        }
        dispatch({
          type: ALERT_UPLOAD,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: error.hasOwnProperty("response")
              ? error.response.data.hasOwnProperty("error_message")
                ? error.response.data.error_message
                : "Please try again later!"
              : "Please try again later!",
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_UPLOAD,
          });
        }, 200);
      });
  };

export const fileDelete = (file_id, level, path, data) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "DELETE",
    url: BACKEND_URL + "aws/s3",
    data: { file_id },
    headers: config,
  })
    .then((response) => {
      if (response.data.success) {
        path = path.split("/");
        path.pop();
        path = path.join("/");
        level.pop();
        dispatch(listFolderChats(path, level, data));
      } else {
        dispatch({
          type: UPLOADING_FILE,
          payload: false,
        });
        dispatch({
          type: ALERT_UPLOAD,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: response.data.error_message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_UPLOAD,
          });
        }, 200);
      }
    })
    .catch((error) => {
      dispatch({
        type: UPLOADING_FILE,
        payload: false,
      });
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 401) {
          dispatch(checkToken());
        }
      }
      dispatch({
        type: ALERT_UPLOAD,
        payload: {
          isError: true,
          isErrorType: "FAIL",
          errMessage: error.hasOwnProperty("response")
            ? error.response.data.hasOwnProperty("error_message")
              ? error.response.data.error_message
              : "Please try again later!"
            : "Please try again later!",
        },
      });
      setTimeout(() => {
        dispatch({
          type: RESET_UPLOAD,
        });
      }, 200);
    });
};

export const uploadInitMulti =
  (files, currentDirPath, index = 0) =>
  async (dispatch) => {
    dispatch({
      type: UPLOADING_MULTI_FILES,
      payload: {
        status: true,
        succeedCount: index,
        failedCount: store.getState().upload.failedCount,
        totalCount: files.length,
      },
    });
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "POST",
      url: BACKEND_URL + "aws/s3",
      data: {
        file_name: files[index].originFileObj.name,
        file_path:
          currentDirPath + "/" + files[index].originFileObj.webkitRelativePath,
      },
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          dispatch(
            s3uploadMulti(
              files,
              response.data.file_url_data.s3_url_data.url,
              response.data.file_url_data.s3_url_data.fields.key,
              response.data.file_url_data.s3_url_data.fields["x-amz-algorithm"],
              response.data.file_url_data.s3_url_data.fields[
                "x-amz-credential"
              ],
              response.data.file_url_data.s3_url_data.fields["x-amz-date"],
              response.data.file_url_data.s3_url_data.fields["policy"],
              response.data.file_url_data.s3_url_data.fields["x-amz-signature"],
              response.data.file_url_data["file_id"],
              files[index].originFileObj.name,
              index,
              currentDirPath
            )
          );
        } else {
          if (index < files.length - 1) {
            dispatch({
              type: UPLOADING_MULTI_FILES,
              payload: {
                status: true,
                succeedCount: index + 1,
                failedCount: store.getState().upload.failedCount + 1,
                totalCount: files.length,
              },
            });
            dispatch(uploadInitMulti(files, currentDirPath, index + 1));
          } else {
            dispatch({
              type: UPLOADING_MULTI_FILES,
              payload: {
                status: true,
                succeedCount: index + 1,
                failedCount: store.getState().upload.failedCount + 1,
                totalCount: files.length,
              },
            });
            dispatch({
              type: ALERT_UPLOAD,
              payload: {
                isError: true,
                isErrorType: "FAIL",
                errMessage: response.data.error_message,
              },
            });
            setTimeout(() => {
              dispatch({
                type: RESET_UPLOAD,
              });
            }, 200);
          }
        }
      })
      .catch((error) => {
        if (error.hasOwnProperty("response")) {
          if (error.response.status === 401) {
            dispatch(checkToken());
          }
        }
        if (index < files.length - 1) {
          dispatch({
            type: UPLOADING_MULTI_FILES,
            payload: {
              status: true,
              succeedCount: index + 1,
              failedCount: store.getState().upload.failedCount + 1,
              totalCount: files.length,
            },
          });
          dispatch(uploadInitMulti(files, currentDirPath, index + 1));
        } else {
          dispatch({
            type: UPLOADING_MULTI_FILES,
            payload: {
              status: true,
              succeedCount: index + 1,
              failedCount: store.getState().upload.failedCount + 1,
              totalCount: files.length,
            },
          });
          dispatch({
            type: ALERT_UPLOAD,
            payload: {
              isError: true,
              isErrorType: "FAIL",
              errMessage: error.hasOwnProperty("response")
                ? error.response.data.error_message
                : "File uploading failed",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_UPLOAD,
            });
          }, 200);
        }
      });
  };

export const s3uploadMulti =
  (
    files,
    url,
    key,
    algorithm,
    credential,
    date,
    policy,
    signature,
    file_id,
    file_name,
    index,
    currentDirPath
  ) =>
  async (dispatch) => {
    var FormData = require("form-data");
    let formdata = new FormData();
    formdata.append("key", key);
    formdata.append("x-amz-algorithm", algorithm);
    formdata.append("x-amz-credential", credential);
    formdata.append("x-amz-date", date);
    formdata.append("policy", policy);
    formdata.append("x-amz-signature", signature);
    formdata.append("file", files[index].originFileObj);
    formdata.append("file_id", file_id);
    var xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.upload.onprogress = function (pe) {
      //     dispatch({
      //       type: IN_PROGRESS_RAW_UPLOAD,
      //       payload: (pe.loaded / pe.total) * 100,
      //     });
    };
    xhr.addEventListener("readystatechange", async function () {
      if (this.readyState === 4) {
        if (this.status === 204 || this.status === 200) {
          dispatch(
            uploadFinalizeMulti(
              file_id,
              true,
              file_name,
              index,
              files,
              currentDirPath
            )
          );
        } else {
          if (index < files.length - 1) {
            dispatch({
              type: UPLOADING_MULTI_FILES,
              payload: {
                status: true,
                succeedCount: index + 1,
                failedCount: store.getState().upload.failedCount + 1,
                totalCount: files.length,
              },
            });
            dispatch(uploadInitMulti(files, currentDirPath, index + 1));
          } else {
            dispatch({
              type: UPLOADING_MULTI_FILES,
              payload: {
                status: true,
                succeedCount: index + 1,
                failedCount: store.getState().upload.failedCount + 1,
                totalCount: files.length,
              },
            });
            dispatch({
              type: ALERT_UPLOAD,
              payload: {
                isError: true,
                isErrorType: "FAIL",
                errMessage: "File uploading failed",
              },
            });
            setTimeout(() => {
              dispatch({
                type: RESET_UPLOAD,
              });
            }, 200);
          }
        }
      }
    });
    xhr.open("POST", url);
    xhr.send(formdata);
  };

export const uploadFinalizeMulti =
  (file_id, isUploaded, file_name, index, files, currentDirPath) =>
  async (dispatch) => {
    const config = {
      "Content-Type": "application/json",
    };
    await axios({
      method: "PUT",
      url: BACKEND_URL + "aws/s3",
      data: { file_id, is_uploaded: isUploaded },
      headers: config,
    })
      .then((response) => {
        if (response.data.success) {
          if (index < files.length - 1) {
            dispatch({
              type: UPLOADING_MULTI_FILES,
              payload: {
                status: true,
                succeedCount: index + 1,
                failedCount: store.getState().upload.failedCount,
                totalCount: files.length,
              },
            });
            dispatch(uploadInitMulti(files, currentDirPath, index + 1));
          } else {
            dispatch({
              type: UPLOADING_MULTI_FILES,
              payload: {
                status: true,
                succeedCount: index + 1,
                failedCount: store.getState().upload.failedCount,
                totalCount: files.length,
              },
            });
            dispatch({
              type: ALERT_UPLOAD,
              payload: {
                isError: true,
                isErrorType: "SUCCESS",
                errMessage: "File uploading completed",
              },
            });
            setTimeout(() => {
              dispatch({
                type: RESET_UPLOAD,
              });
            }, 200);

            dispatch(listChats());
          }
        } else {
          if (index < files.length - 1) {
            dispatch({
              type: UPLOADING_MULTI_FILES,
              payload: {
                status: true,
                succeedCount: index + 1,
                failedCount: store.getState().upload.failedCount + 1,
                totalCount: files.length,
              },
            });
            dispatch(uploadInitMulti(files, currentDirPath, index + 1));
          } else {
            dispatch({
              type: UPLOADING_MULTI_FILES,
              payload: {
                status: true,
                succeedCount: index + 1,
                failedCount: store.getState().upload.failedCount + 1,
                totalCount: files.length,
              },
            });
            dispatch({
              type: ALERT_UPLOAD,
              payload: {
                isError: true,
                isErrorType: "FAIL",
                errMessage: "File uploading failed",
              },
            });
            setTimeout(() => {
              dispatch({
                type: RESET_UPLOAD,
              });
            }, 200);
          }
        }
      })
      .catch((error) => {
        if (index < files.length - 1) {
          dispatch({
            type: UPLOADING_MULTI_FILES,
            payload: {
              status: true,
              succeedCount: index + 1,
              failedCount: store.getState().upload.failedCount + 1,
              totalCount: files.length,
            },
          });
          dispatch(uploadInitMulti(files, currentDirPath, index + 1));
        } else {
          dispatch({
            type: UPLOADING_MULTI_FILES,
            payload: {
              status: true,
              succeedCount: index + 1,
              failedCount: store.getState().upload.failedCount + 1,
              totalCount: files.length,
            },
          });
          dispatch({
            type: ALERT_UPLOAD,
            payload: {
              isError: true,
              isErrorType: "FAIL",
              errMessage: "File uploading failed",
            },
          });
          setTimeout(() => {
            dispatch({
              type: RESET_UPLOAD,
            });
          }, 200);
        }
      });
  };

export const listFolderChats = (path, level, data) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "GET",
    url: BACKEND_URL + "aws/s3?order_by=1&current_file_path=" + path,
    headers: config,
  })
    .then(async (response) => {
      if (response.data.success) {
        var dataz = [...data];
        if (path === "") {
          dataz = response.data.file_data.map((ele) => {
            return {
              file: ele.name,
              data: [],
              page_limit: ele.page_limit,
              isSelected: false,
              file_id: ele.id,
              is_folder: ele.is_folder,
              folderData: [],
              totalCount: ele.is_folder ? ele.total_files_count : 0,
              processedCount: ele.is_folder ? ele.files_processed_count : 0,
              is_folder_open: false,
              isSummary:
                ele.is_summary_generated === null
                  ? false
                  : ele.is_summary_generated,
              isProcessed:
                ele.is_file_processed === null ? true : ele.is_file_processed,
            };
          });
        } else {
          dataz = data.map((item) => {
            return { ...item, isSelected: false };
          });
          dataz = await recersive(dataz, [...level]);
          function recersive(data, point) {
            if (point.length === 1) {
              let history = [];
              response.data.file_data.forEach((ele) => {
                history.push({
                  ...ele,
                  file: ele.name,
                  data: [],
                  page_limit: ele.page_limit,
                  isSelected: false,
                  file_id: ele.id,
                  is_folder: ele.is_folder,
                  folderData: [],
                  totalCount: ele.is_folder ? ele.total_files_count : 0,
                  processedCount: ele.is_folder ? ele.files_processed_count : 0,
                  is_folder_open: false,
                  isSummary:
                    ele.is_summary_generated === null
                      ? false
                      : ele.is_summary_generated,
                  isProcessed:
                    ele.is_file_processed === null
                      ? true
                      : ele.is_file_processed,
                });
              });
              data = data.map((ele) => {
                return { ...ele, is_folder_open: false, isSelected: false };
              });
              data[point[0]].folderData = history;
              data[point[0]].is_folder_open = true;
              data[point[0]].isSelected = true;
              return data;
            } else {
              let curr = point.shift();
              data = data.map((elt) => {
                return { ...elt, isSelected: false };
              });
              data[curr].is_folder_open = true;
              data[curr].folderData = recersive(data[curr].folderData, point);
              return data;
            }
          }
        }
        sessionStorage.setItem("history", JSON.stringify(dataz));
        dispatch({ type: SET_FILE_DATA, payload: dataz });
        dispatch({ type: TRIGGER_CONVERSATION, payload: true });
      } else {
        dispatch({
          type: ALERT_UPLOAD,
          payload: {
            isError: true,
            isErrorType: "FAIL",
            errMessage: response.data.error_message,
          },
        });
        setTimeout(() => {
          dispatch({
            type: RESET_UPLOAD,
          });
        }, 200);
      }
    })
    .catch((error) => {
      if (error.hasOwnProperty("response")) {
        if (error.response.status === 401) {
          dispatch(checkToken());
        }
      }
      dispatch({
        type: ALERT_UPLOAD,
        payload: {
          isError: true,
          isErrorType: "FAIL",
          errMessage: error.hasOwnProperty("response")
            ? error.response.data.hasOwnProperty("error_message")
              ? error.response.data.error_message
              : "Please try again later!"
            : "Please try again later!",
        },
      });
      setTimeout(() => {
        dispatch({
          type: RESET_UPLOAD,
        });
      }, 200);
    });
};

export const reDoProcessing = (file_id, isUploaded) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "PUT",
    url: BACKEND_URL + "aws/s3",
    data: { file_id, is_uploaded: isUploaded },
    headers: config,
  })
    .then((response) => {
      if (response.data.success) {
      } else {
      }
    })
    .catch((error) => {});
};

export const syncFiles = (file) => async (dispatch) => {
  const config = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "PUT",
    url: BACKEND_URL + "nextcloud/sync",
    headers: config,
    data: { file_id: file },
  })
    .then((response) => {
      if (response.data.success) {
      } else {
      }
    })
    .catch((error) => {
      dispatch({
        type: ALERT_UPLOAD,
        payload: {
          isError: true,
          isErrorType: "FAIL",
          errMessage: error.hasOwnProperty("response")
            ? error.response.data.hasOwnProperty("error_message")
              ? error.response.data.error_message
              : "Please try again later!"
            : "Please try again later!",
        },
      });
      setTimeout(() => {
        dispatch({
          type: RESET_UPLOAD,
        });
      }, 200);
    });
};

let getTextGenerationChats = null;
export const getTextGenerationChatsData = async () => {
  const url = "chat/list-text-gen";
  if (getTextGenerationChats !== null) {
    getTextGenerationChats.cancel("New Call made");
    getTextGenerationChats = null;
  }
  getTextGenerationChats = axios.CancelToken.source();
  try {
    const response = await axios.get(BACKEND_URL + url, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: getTextGenerationChats.token,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

let getTextGenerationChatHistory = null;
export const getTextGenerationChatHistoryData = async (conv_id, text_gen) => {
  const url = "chat/conversation?conv_id=" + conv_id + "&text_gen=" + text_gen;
  if (getTextGenerationChatHistory !== null) {
    getTextGenerationChatHistory.cancel("New Call made");
    getTextGenerationChatHistory = null;
  }
  getTextGenerationChatHistory = axios.CancelToken.source();
  try {
    const response = await axios.get(BACKEND_URL + url, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: getTextGenerationChatHistory.token,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

let getResponseForQuery = null;
export const getResponseForQueryData = async (values) => {
  const url = "chat/conversation";
  if (getResponseForQuery !== null) {
    getResponseForQuery.cancel("New Call made");
    getResponseForQuery = null;
  }
  getResponseForQuery = axios.CancelToken.source();
  try {
    const response = await axios.post(BACKEND_URL + url, values, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: getResponseForQuery.token,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

let getFilesForSelection = null;
export const getFilesForSelectionData = async (path) => {
  const url = "aws/s3?order_by=1&current_file_path=" + path;
  if (getFilesForSelection !== null) {
    getFilesForSelection.cancel("New Call made");
    getFilesForSelection = null;
  }
  getFilesForSelection = axios.CancelToken.source();
  try {
    const response = await axios.get(BACKEND_URL + url, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: getFilesForSelection.token,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

let uploadToS3Init = null;
export const uploadToS3InitFunc = async (value) => {
  const url = "aws/s3";
  if (uploadToS3Init !== null) {
    uploadToS3Init.cancel("New Call made");
    uploadToS3Init = null;
  }
  uploadToS3Init = axios.CancelToken.source();
  try {
    const response = await axios.post(BACKEND_URL + url, value, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: uploadToS3Init.token,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

let uploadToS3Finalize = null;
export const uploadToS3FinalizeFunc = async (value) => {
  const url = "aws/s3";
  if (uploadToS3Finalize !== null) {
    uploadToS3Finalize.cancel("New Call made");
    uploadToS3Finalize = null;
  }
  uploadToS3Finalize = axios.CancelToken.source();
  try {
    const response = await axios.put(BACKEND_URL + url, value, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: uploadToS3Finalize.token,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};

let checkProcceed = null;
export const checkProcceedFunc = async (value) => {
  const url = "aws/s3?file_id=" + value;
  if (checkProcceed !== null) {
    checkProcceed.cancel("New Call made");
    checkProcceed = null;
  }
  checkProcceed = axios.CancelToken.source();
  try {
    const response = await axios.get(BACKEND_URL + url, value, {
      headers: {
        "Content-Type": "application/json",
      },
      cancelToken: checkProcceed.token,
    });
    return response.data;
  } catch (error) {
    return null;
  }
};
